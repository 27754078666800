/**
 * 제작 : 한승현
 * 날짜 : 2023/01/10
 * 내용 : GoogleButtonOut
 */

import React from 'react';
import { GoogleLogout } from 'react-google-login';
import { useNavigate } from 'react-router-dom';

function GoogleLogoutButton() {
  const navigate = useNavigate();
  const onSuccess = (response) => {
    console.log('-> response', response);

    sessionStorage.removeItem('isOhbrown');
    navigate('/');
  };

  const onFailure = (response) => {
    console.log(response);
  };

  return (
    <GoogleLogout
      clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}
      buttonText={'로그아웃'}
      onLogoutSuccess={onSuccess}
      onFailure={onFailure}
    ></GoogleLogout>
  );
}

export default GoogleLogoutButton;
