/**
 * 제작 : 한승현
 * 날짜 : 2022-10-06
 * 내용 : Main 페이지 !isAuthorized ? login : map
 */

import React, { useState } from 'react';

import { MainWrapStyled } from '../../stylesheets/main/main';
import { ToastContainer } from 'react-toastify';
import Control from '../../components/control/Control';
import KakaoMap from '../../components/map/KakaoMap';
import GoogleLogoutButton from '../../components/login/GoogleLogoutButton';

function Main() {
  const [mapWidth, setMapWidth] = useState();
  const isOhbrown = sessionStorage.getItem('isOhbrown') === 'true' ? true : false;

  return (
    <>
      {isOhbrown ? (
        <MainWrapStyled>
          <GoogleLogoutButton />
          <ToastContainer />
          <Control setMapWidth={setMapWidth} mapWidth={mapWidth} />
          <KakaoMap mapWidth={mapWidth} />
        </MainWrapStyled>
      ) : (
        <div>추후 공개 예정입니다</div>
      )}
    </>
  );
}

export default Main;
