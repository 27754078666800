import styled from 'styled-components';

const LoginWrapStyled = styled.div`
  width: 100vw;
  height: 100vh;
`;
const LoginBoxStyled = styled.div`
  box-sizing: border-box;
  width: 40%;
  margin: 33vh auto;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgb(0, 0, 0, 0.4);
  padding: 100px;
  text-align: center;
`;

const LoginMessageStyled = styled.p`
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
`;
export { LoginWrapStyled, LoginBoxStyled, LoginMessageStyled };
