/**
 * 제작: hanseunghyeon
 * 날짜: 23/01/31
 * 내용: seoul api 에서 총 아이템 length를 반환한다
 */

/**
 * result 에서 total count 를 반환 => callback 실행 여부를 판단한다.
 * @param result
 * @return {*}
 */
export function getTotalItemLength(result) {
  const resultBody = result.data[Object.keys(result.data)[0]];

  const resultBodyKeys = Object.keys(resultBody);

  const resultItemsLength = resultBody[resultBodyKeys[0]];
  // console.log('-> resultItemsLength', resultItemsLength);

  const totalItemLength = resultItemsLength;

  return totalItemLength;
}
