/**
 * 제작: hanseunghyeon
 * 날짜: 22/11/01
 * 내용: api 호출 등에 사용되는 파라미터
 */

export const BASE_URL = 'https://api.houseofooh.com';

export const URL_LIST = {
  BUS: '/bus-list',
  BUSPATH: '/bus-path-list',
  BUSSTATION: '/bus-station-list',
  SEOUL_DATA: '/seoul-data',
  CITYPATH: '/city-path',
  USER_LOG: '/user-log',
};

export const SEOUL_SERVICE_NAME = {
  SHELTER: 'busStopLocationXyInfo',
  BUS: 'tbisMasterRoute',
};
