/**
 * 제작: emirue
 * 날짜: 22/10/18
 * 내용: 네비게이션, 검색 라벨에 표기할 텍스트
 */

export const MODE_LIST_ITEMS = {
  BUS: 'BUS',
  SHELTER: 'SHELTER'
};

export const MODE_LIST_ITEMS_LABEL = {
  BUS: '버스 노선',
  SHELTER: '정류장',
};

