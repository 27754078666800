import styled from 'styled-components';

const CurrentWrapStyled = styled.div`
  margin: 30px 20px;
  padding: 20px 10px;
  border-radius: 8px;

  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  height: 60vh;
  overflow-y: auto;
`;

const CurrentTitleStyled = styled.h2`
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  & span {
    color: #999999;
    margin-left: 1.1em;
    text-align: right;
    font-size: .8em;
  }
`;

const CurrentCountStyled = styled.span``;
export { CurrentWrapStyled, CurrentTitleStyled, CurrentCountStyled };
