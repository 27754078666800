/**
 * 제작: hanseunghyeon
 * 날짜: 23/02/14
 * 내용: tostify에서 사용하는 메세지 및 옵션
 */
// search error message

import {toast} from "react-toastify";

export const ALERT_MESSAGE = {
    SUCCESS: '추가되었습니다',
    KEYWORD: '항목을 선택해주세요',
    ISEXISTS: '이미 추가된 항목 입니다',
}

export const TOSTIFY_OPTIONS = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 1000,
}
