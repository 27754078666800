/**
 * 제작 : 한승현
 * 날짜 : 2022/10/12
 * 내용 : ModeItem
 */

import React from 'react';
import classNames from 'classnames';
import {
  ModeItemButtonStyled,
  ModeItemWrapStyled,
} from '../../stylesheets/mode/modeItem';

import { useNavigate, useParams } from 'react-router-dom';

function ModeNav({ item }) {
  const navigate = useNavigate();
  const { mode } = useParams();

  function onClick(event) {
    const { value } = event.target;
    navigate(`/${value}`);
  };

  return (
    <ModeItemWrapStyled>
      <ModeItemButtonStyled
        className={classNames({ active: mode === item })}
        value={item}
        onClick={onClick}
      >
        {item}
      </ModeItemButtonStyled>
    </ModeItemWrapStyled>
  );
}

export default ModeNav;
