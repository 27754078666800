/**
 * 제작 : 한승현
 * 날짜 : 2023/01/28
 * 내용 : login route
 */

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './pages/Main';
import LoginPage from './pages/LoginPage';

function RoutePage() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path=":mode" element={<Main />} />
      </Routes>
    </BrowserRouter>
  );
}

export default RoutePage;
