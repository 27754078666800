import styled from 'styled-components';

const HeaderWrapStyled = styled.div`
  width: 100%;
`
const HeaderTitleStyled = styled.h1`
  text-align: center;
  font-size: 1.8em;
  font-weight: 700;
`

export {
    HeaderWrapStyled,
    HeaderTitleStyled,
};