/**
 * 제작: hanseunghyeon
 * 날짜: 23/01/31
 * 내용: seoul api items=[] 을 받아서 object로 반환한다
 */
import { SEOUL_SERVICE_NAME } from '../../consts/BaseUrl';
import { MODE_LIST_ITEMS } from '../../consts/ModeList';

/**
 * []를 객체로 반환한다.
 * @param items
 * @param key
 * @return {{}}
 */
export function getNextObject(items, key) {
  // items []
  const nextData = {};

  for (const item of items) {
    const label = item.STOP_NM || item.ROUTE_NM;
    const value = item.STOP_NO || item.ROUTE_ID;
    const visible = true;

    nextData[value] = {
      label,
      value,
      visible,
    };

    if (key === SEOUL_SERVICE_NAME.BUS) {
      nextData[value].type = MODE_LIST_ITEMS.BUS;
      // BUS ROUTE TYPE 추가
      nextData[value].routeType = item.ROUTE_TYPE;
    }

    if (key === SEOUL_SERVICE_NAME.SHELTER) {
      nextData[value].type = MODE_LIST_ITEMS.SHELTER;
      // SHELTER POSITION 추가
      nextData[value].gpsX = item.XCODE;
      nextData[value].gpsY = item.YCODE;
    }
  }

  return nextData;
}
