/**
 * 제작: hanseunghyeon
 * 날짜: 23/02/15
 * 내용: list store set remove handler
 */

/**
 * polyline, marker store remove시 false 한다
 * @param value
 * @param prevList
 * @return {*}
 */
export function setRemoveList (value, prevList) {
  const nextList = { ...prevList };

  nextList[value].visible = false;

  return nextList;
}