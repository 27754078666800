/**
 * 제작: hanseunghyeon
 * 날짜: 23/02/01
 * 내용: SEOUL API DATA 에서 초기 값을 할당 받는 함수
 */
import { getList } from './getList';
import { BASE_URL, URL_LIST } from '../../consts/BaseUrl';

/**
 * service name 을 받아서 해당 api를 호출한다
 * @param service
 * @return {Promise<{[p: string]: *}|재귀함수>}
 */
export async function getInitData (service) {
  const url = BASE_URL + URL_LIST.SEOUL_DATA;
  const start = '1';
  const preData = {};
  const result = await getList(url, service, start, preData);
  // 배열로 변환.... 추후에 객체로 통일...
  // const nextData = Object.keys(result)?.map((item)=>{
  //   return result[item];
  // })

  return result;
}
