/**
 * 제작: hanseunghyeon
 * 날짜: 23/02/19
 * 내용: 버스의 routeType 타입에 맞게 색상을 반환한다
 */

import BUS_ROUTE_TYPES from '../../consts/BusRouteType';

/**
 * 버스의 타입에 맞게 색상을 리턴한다
 * @param routeType
 * @return {*}
 */
export function getBusColor(routeType) {
  // console.log('-> routType', routeType);
  // console.log('-> BUS_ROUTE_TYPES[routeType]', BUS_ROUTE_TYPES[routeType]);
  return BUS_ROUTE_TYPES[routeType].COLOR;
}
