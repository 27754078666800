/**
 * 제작: hanseunghyeon
 * 날짜: 23/02/15
 * 내용: 정류장 위치를 표시할 마커 생성
 */
import { MARKER_OPTIONS } from '../../consts/kakaoMapOptions';

const { kakao } = window;

/**
 * marker 를 만들어서 반환하는 함수
 * @param item
 * @return {Property.Marker}
 */
export function createMarker(item) {
  // console.log('-> item', item);
  const { gpsX, gpsY, label } = item;
  // console.log('-> value', label);

  // 마커가 표시될 위치
  const markerPosition = new kakao.maps.LatLng(gpsY, gpsX);

  // 마커 생성
  const marker = new kakao.maps.Marker({
    ...MARKER_OPTIONS,
    position: markerPosition,
  });

  return marker;
}
