/**
 * 제작 : 한승현
 * 날짜 : 23/01/17
 * 내용 : get client Ip
 */

import axios from 'axios';

const SERVICE_URL = 'https://jsonip.com/';

/**
 * 클라이언트의 public ip를 리턴하는 함수
 * @return {Promise<AxiosResponse<any>>} string
 */
export async function getIp() {
  const response = await axios.get(SERVICE_URL);
  // console.log('-> response', response);
  const { data } = response;
  // console.log('-> data', data);
  const { ip } = data;

  // console.log('-> ip', ip);

  return ip;
}
