import styled from 'styled-components';

const SearchWrapStyled = styled.form`
  margin: 30px 20px 0;
`;

const SearchFlexBoxStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SearchModeListStyled = styled.ul`
  display: flex;
  justify-content: center;
  gap: 1px;
  margin: 30px 20px;
  & > li {
    width: 100%;
  }
`;

const SearchKeywordStyled = styled.label`
  flex: 1;
  > input {
    padding: 10px;
  }
`;

const SearchInputSubmitStyled = styled.button`
  max-width: 40px;
  width: 30%;
`;

export {
  SearchWrapStyled,
  SearchFlexBoxStyled,
  SearchModeListStyled,
  SearchKeywordStyled,
  SearchInputSubmitStyled,
};
