import styled from 'styled-components';

const ControlWrapStyled = styled.div`
  height: 100vh;
  padding: 30px 10px;
  box-shadow: 4px 4px 12px 2px #ccc;
  position: relative;
  width: ${(props) => (props.visible ? '520px' : '50px')};
  background: ${(props) => (props.visible ? '#fff' : 'transparent')};
  left: ${(props) => (props.visible ? '0%' : '-80%')};
  z-index: 2;
`;

const ControlToggleStyled = styled.div`
  position: absolute;
  height: 40px;
  padding: 10px;
  text-align: center;
  font-size: 1.2em;
  transition: all 0.5s;
  color: #999;
  z-index: 3;

  :hover {
    cursor: pointer;
    font-size: 1.3em;
    color: #333;
  }
`;

export { ControlWrapStyled, ControlToggleStyled };
