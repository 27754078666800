import styled from 'styled-components';

const MainWrapStyled = styled.div`
  display: flex;

  //로그아웃 버튼 삭제 예정
  & > button {
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 40;
    border: 0;
  }
`;

export { MainWrapStyled };
