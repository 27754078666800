/**
 * 제작 : 한승현
 * 날짜 : 2022/10/12
 * 내용 : Control area (왼쪽 메뉴)
 */

import React, { useState } from 'react';
import {
  ControlWrapStyled,
  ControlToggleStyled,
} from '../../stylesheets/control/control';
import Header from '../header/Header';
import Search from '../search/Search';
import Current from '../current/Current';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import {useParams} from 'react-router-dom';

function Control({ mapWidth, setMapWidth }) {
  const { mode } = useParams();
  const [visible, setVisible] = useState(true);

  function handleOnclick() {
    console.log('-> toggle', visible);
    setVisible(!visible);
    setMapWidth(!mapWidth);
  }

  return (
    <>
        <ControlWrapStyled visible={visible}>
          <Header />
          <Search mode={mode} />
          <Current />
        </ControlWrapStyled>
        <ControlToggleStyled onClick={handleOnclick}>
          {!visible ? <FaAngleRight /> : <FaAngleLeft />}
        </ControlToggleStyled>
    </>
  );
}

export default Control;
