/**
 * 제작 : 한승현
 * 날짜 : 2023/01/28
 * 내용 : LoginPage
 */

import React from 'react';
import { MainWrapStyled } from '../../stylesheets/main/main';
import LoginMain from '../../components/login/LoginMain';

function LoginPage() {
  sessionStorage.clear();

  return (
    <MainWrapStyled>
      <LoginMain />
    </MainWrapStyled>
  );
}

export default LoginPage;
