import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from './containers/Root';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Root />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// 시연 후 피드백 내용
// 흑백 지도
// 노선 명 표시
// 사용자에게 색상을 지정할 수 있게 하는 방법? => 있으면 좋을 듯
// 버스 타입에 따라 색상을 준다.
// 노선이 겹치면 강조 색상을 주고 싶다.
// 핫플 그룹으로 보여줄 수 있을까?
