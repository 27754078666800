/**
 * 제작 : 한승현
 * 날짜 : 2023/01/09
 * 내용 : GoogleButton
 */
import React from 'react';
import { GoogleLogin } from 'react-google-login';
import { useNavigate, useParams } from 'react-router-dom';
import { putDynamodb } from '../../utils/dynamodb/putDynamodb';
import { MODE_LIST_ITEMS } from '../../consts/ModeList';
import { setIsOhbrown } from '../../utils/login/setIsOhbrown';
import { gapi } from 'gapi-script';

function GoogleLoginButton() {
  const { mode } = useParams();
  // console.log('-> mode', mode);

  const navigate = useNavigate();

  /**
   * 로그인 성공 : 로그인된 구글 계정 정보를 람다로 전달하는 함수
   * @param response
   * @return {Promise<void>}
   */
  const onSuccess = async (response) => {
    // console.log('-> response', response);
    if (response) {
      putDynamodb(response);
      setIsOhbrown(response);
      if (mode === undefined) {
        navigate(`${MODE_LIST_ITEMS.BUS}`);
      } else {
        navigate('/');
      }
    }
  };

  /**
   * 로그인 실패 : 콘솔 출력
   * @param response
   */
  const onFailure = (response) => {
    // console.log(response);
    sessionStorage.clear();
    navigate('/');
  };

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID} // api access key
      buttonText="로그인" // 버튼 텍스트
      onSuccess={onSuccess} // 성공 처리 함수
      onFailure={onFailure} // 실패 처리 함수
      isSignedIn={false} // 로그인 유지
      hostedDomain={'ohbrown.co.kr'}
    />
  );
}

export default GoogleLoginButton;
