/**
 * 제작 : hanseunghyeon
 * 날짜 : 23/01/27
 * 내용 : 접속 로그를 dynamoDB에 기록
 */

import axios from 'axios';
import { BASE_URL, URL_LIST } from '../../consts/BaseUrl';
import { getIp } from '../getIp/getIp';

const SERVICE_URL = BASE_URL + URL_LIST.USER_LOG;

/**
 * 구글 profile & public ip 정보를 dynamoDB로 전송
 * @param response
 * @return {Promise<void>}
 */
export async function putDynamodb(response) {
  const { profileObj } = response;
  const userId = profileObj.email;
  const userName = profileObj.familyName + profileObj.givenName;
  const userIp = await getIp();

  const userInfo = {
    userId,
    userName,
  };

  const newLog = {
    userIp,
    ...userInfo,
  };

  // console.log('-> newLog', newLog);
  await axios.post(SERVICE_URL, newLog);
}
