/**
 * 제작: hanseunghyeon
 * 날짜: 23/01/31
 * 내용: seoul api result 에서 data 를 반환한다.
 */

import { getNextObject } from './getNextObject';

/**
 * seoul api result 에서 data를 반환한다
 * @param result
 * @return {*}
 */
export function getNewItems(result) {
  // console.log('-> Object.keys(result.data)', Object.keys(result.data));
  const key = Object.keys(result.data)[0];
  const resultBody = result.data[key];
  // console.log('-> resultBody', resultBody);

  const resultBodyKeys = Object.keys(resultBody);
  // console.log('-> resultBodyKeys', resultBodyKeys);

  const resultBodyItems = resultBody[resultBodyKeys[2]];
  // console.log('-> resultBodyItems', resultBodyItems);

  const newItem = getNextObject(resultBodyItems, key);

  return newItem;
}
