/**
 * 제작: hanseunghyeon
 * 날짜: 23/02/14
 * 내용: 카카오 맵에 표시하기 위한 아이템들의 좌표를 생성하기 위한 함수
 */

const { kakao } = window;

/**
 * 좌표 배열을 카카오 좌표로 반환하는 함수
 * @param position
 * @return {*[]}
 */
export function getKakaoLatLng(path) {
  // console.log('-> path', path);

  const kakaoLatLng = path.map((item) => {
    const { gpsY, gpsX } = item;
    return new kakao.maps.LatLng(gpsY, gpsX);
  });

  return kakaoLatLng;
}
