/**
 * 제작 : 한승현
 * 날짜 : 2023/01/28
 * 내용 : LoginMain
 */

import React from 'react';
import {
  LoginWrapStyled,
  LoginBoxStyled,
  LoginMessageStyled,
} from '../../stylesheets/login/loginmain';
import GoogleLoginButton from './GoogleLoginButton';

function LoginMain() {

  return (
    <LoginWrapStyled>
      <LoginBoxStyled>
        <LoginMessageStyled>로그인 후 이용 가능합니다</LoginMessageStyled>
        <GoogleLoginButton />
        {/*<GoogleLogoutButton />*/}
      </LoginBoxStyled>
    </LoginWrapStyled>
  );
}

LoginMain.propTypes = {};
LoginMain.defaultProps = {};

export default LoginMain;
