/**
 * 제작 : 한승현
 * 날짜 : 2022/11/08
 * 내용 : Current mode에 따라 current를 보여준다.
 */
import React from 'react';
import {
  CurrentWrapStyled,
  CurrentTitleStyled,
  CurrentCountStyled,
} from '../../stylesheets/current/current';
import CurrentList from './CurrentList';
import { useParams } from 'react-router-dom';
import { useCurrentListStore } from '../../store/currentList';

function Current() {
  const { mode } = useParams();
  const { currentList } = useCurrentListStore((state) => state);

  return (
    <CurrentWrapStyled>
      <CurrentTitleStyled>
        Current {mode} List
        <CurrentCountStyled>
          {Object.keys(currentList[mode]).length}개
        </CurrentCountStyled>
      </CurrentTitleStyled>
      <CurrentList />
    </CurrentWrapStyled>
  );
}

export default Current;
