/**
 * 제작 : 한승현
 * 날짜 : 2022/10/12
 * 내용 : KakaoMap
 */

import React, { useRef, useState } from 'react';
import { useUpdateEffect, useMountEffect } from '@react-hookz/web';
import { KakaoMapStyled } from '../../stylesheets/map/kakaoMap';
import { usePolyLineListStore } from '../../store/polyLineList';
import { useMarkerListStore } from '../../store/markerList';
const { kakao } = window;

function KakaoMap({ mapWidth }) {
  const { polyLineList } = usePolyLineListStore((state) => state);
  const { markerList } = useMarkerListStore((state) => state);
  const [kakaoMap, setKakaoMap] = useState(null);
  const oMap = useRef();

  // 지도의 기준이 되는 좌표
  const LOCATION = {
    lat: 37.5662952,
    lng: 126.9779451,
  };

  /**
   * 초기 맵을 그리고 State 만드는 함수
   */
  function initMap() {
    const options = {
      center: new kakao.maps.LatLng(LOCATION.lat, LOCATION.lng),
      level: 9,
    };

    const map = new kakao.maps.Map(oMap.current, options);

    setKakaoMap(map);
    drawPolyline();
    drawMarker();
  }

  /**
   * polyline 그리는 함수
   */
  function drawPolyline() {
    for (const item of Object.values(polyLineList)) {
      // console.log('item', item);
      const { visible, polyline, label } = item;
      const { start, half } = label;
      // console.log(`visible:`, visible);
      if (visible) {
        // console.log('-> show');
        polyline.setMap(kakaoMap);
        start.setMap(kakaoMap);
        half.setMap(kakaoMap);
      }
      if (!visible) {
        // console.log('-> hide');
        polyline.setMap(null);
        start.setMap(null);
        half.setMap(null);
      }
    }
  }

  /**
   * marker 찍는 함수
   */
  function drawMarker() {
    for (const item of Object.values(markerList)) {
      // console.log('update item', item);
      const { marker, markerInfo, visible } = item;
      // console.log('-> marker', marker);
      // console.log('-> visible', visible);

      if (visible) {
        // console.log('-> show');
        marker.setMap(kakaoMap);

        kakao.maps.event.addListener(marker, 'mouseover', function () {
          markerInfo.open(kakaoMap, marker);
        });
        kakao.maps.event.addListener(marker, 'mouseout', function () {
          markerInfo.close();
        });
      }
      if (!visible) {
        // console.log('-> hide');
        marker.setMap(null);
      }
    }
  }

  // 초기 맵 세팅
  useMountEffect(() => {
    if (kakaoMap === null) {
      initMap();
    }
    // 지역 아웃라인
    // fetch('https://api.vworld.kr/req/wfs?SERVICE=WFS&REQUEST=GetFeature&TYPENAME=lt_c_adsido_info&BBOX=13987670,3912271,14359383,4642932&PROPERTYNAME=&VERSION=1.1.0&MAXFEATURES=40&SRSNAME=EPSG:5181&OUTPUT=GML3&EXCEPTIONS=text/xml&KEY=DDD5CE46-0D89-34FD-A31E-7B59A40FBD68&DOMAIN=https://busmap.houseofooh.com')
    //     .then((response) => response.json())
    //     .then((data) => console.log(data));
    // 여기까지 테스트
  }, [oMap]);

  // 화면 크기 바뀌면
  useUpdateEffect(() => {
    console.log('-> mapWidth changed');
    kakaoMap.relayout();
  }, [mapWidth]);

  // polyLineList 업데이트 시 지도에 반영
  useUpdateEffect(() => {
    drawPolyline();
  }, [polyLineList]);

  useUpdateEffect(() => {
    // console.log('-> markerList update', markerList);
    drawMarker();
  }, [markerList]);

  return <KakaoMapStyled id="oMap" ref={oMap} />;
}
export default KakaoMap;
