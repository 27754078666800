/**
 * 제작 : 한승현
 * 날짜 : 2022/11/08
 * 내용 : CurrentList 선택된 아이템 렌더
 */

import React from 'react';
import {
  CurrentListWrapStyled,
  CurrentListIconBoxStyled,
  CurrentListInfoStyled,
  CurrentListInfoWrapStyled,
  CurrentListItemLabelStyled,
  CurrentListItemIdStyled,
  CurrentListItemStyled,
  CurrentListButtonStyle,
} from '../../stylesheets/current/currentList';
import { useCurrentListStore } from '../../store/currentList';
import { usePolyLineListStore } from '../../store/polyLineList';
import { useMarkerListStore } from '../../store/markerList';
import { FaEye, FaEyeSlash, FaTrash, FaBus, FaHome } from 'react-icons/fa';
import { MODE_LIST_ITEMS } from '../../consts/ModeList';

import { useParams } from 'react-router-dom';

function CurrentList() {
  const { currentList, setRemoveList, setToggleVisible } = useCurrentListStore(
    (state) => state
  );
  const { polyLineList, setTogglePolyLineList, setRemovePolyLineList } =
    usePolyLineListStore((state) => state);
  const { markerList, setToggleMarkerList, setRemoveMarkerList } = useMarkerListStore(state => state) ;
  const { mode } = useParams();

  /**
   * 리스트 버튼 핸들러
   * @param {event} e
   */
  function handleOnclickToggle(e) {
    const { value } = e.currentTarget;

    setToggleVisible(mode, value, currentList);

    if (mode === MODE_LIST_ITEMS.BUS) {
      setTogglePolyLineList(value, polyLineList);
    }

    if (mode === MODE_LIST_ITEMS.SHELTER) {
      setToggleMarkerList(value, markerList);
    }
  }

  /**
   * 리스트 버튼 핸들러
   * @param {event} e
   */
  function handleOnclickRemove(e) {
    const { value } = e.currentTarget;

    setRemoveList(mode, value, currentList);

    if (mode === MODE_LIST_ITEMS.BUS) {
      setRemovePolyLineList(value, polyLineList);
    }

    if (mode === MODE_LIST_ITEMS.SHELTER) {
      setRemoveMarkerList(value, markerList);
    }
  }

  return (
    <CurrentListWrapStyled>
      {Object.keys(currentList[mode]).map((key, index) => {
        const item = currentList[mode][key];

        return (
          <CurrentListItemStyled key={`${mode}-item-${index}`}>
            <CurrentListIconBoxStyled color={item.color}>
              {mode === MODE_LIST_ITEMS.BUS ? <FaBus /> : <FaHome />}
            </CurrentListIconBoxStyled>
            <CurrentListInfoStyled>
              <CurrentListInfoWrapStyled>
                <CurrentListItemLabelStyled>
                  {item.label}
                </CurrentListItemLabelStyled>
                <CurrentListItemIdStyled>
                  ({item.value})
                </CurrentListItemIdStyled>
              </CurrentListInfoWrapStyled>
              <CurrentListInfoWrapStyled>
                {mode === MODE_LIST_ITEMS.BUS && item.station ? (
                  `${item.station.start} - ${item.station.end}`
                ) : (
                  <></>
                )}
              </CurrentListInfoWrapStyled>
            </CurrentListInfoStyled>
            <CurrentListButtonStyle
              onClick={handleOnclickToggle}
              value={item.value}
            >
              {item.visible ? (
                <FaEye value={item.value} />
              ) : (
                <FaEyeSlash value={item.value} />
              )}
            </CurrentListButtonStyle>
            <CurrentListButtonStyle
              onClick={handleOnclickRemove}
              value={item.value}
            >
              <FaTrash />
            </CurrentListButtonStyle>
          </CurrentListItemStyled>
        );
      })}
    </CurrentListWrapStyled>
  );
}

export default CurrentList;
