import styled from 'styled-components';
import { POLYLINE_OPTIONS } from '../../consts/kakaoMapOptions';

const KakaoMapStyled = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 1;


  > div > div > div > img {
    // 지도 흑백을 위한 CSS
    filter: grayscale(100%);
  }

  .polyline {
    // 버스 노선 라벨 CSS
    color: #fff;
    opacity: ${POLYLINE_OPTIONS.strokeOpacity};
    font-size: 12px;
    text-align: center;
    padding: 0 4px;
    border-radius: 4px;
    font-weight: 600;
  }
`;

export { KakaoMapStyled };
