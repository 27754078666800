/**
 * 제작 : 한승현
 * 날짜 : 2023/02/08
 * 내용 : 검색창에 적용할 아이템 리스트
 */

import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getOptions } from '../../utils/callSeoulApi/getOptions';
import { useParams } from 'react-router-dom';
import { MODE_LIST_ITEMS_LABEL } from '../../consts/ModeList';

function SelectList({ value, setValue }) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const { mode } = useParams();

  useEffect(() => {
    let active = true;
    // console.log('-> loading', loading);
    if (!loading) {
      return undefined;
    }

    (async () => {
      const data = await getOptions(); // For demo purposes.

      if (active) {
        setOptions([...data[mode]]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
    setValue([]);
  }, [mode]);

  return (
    <>
      <Autocomplete
        value={value}
        multiple
        id="keyword"
        options={options}
        getOptionLabel={(option) => `${option.label} (${option.value})`}
        filterSelectedOptions
        sx={{ width: 320 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(event, newValue) => {
          // console.log('-> newValue', newValue);
          setValue(newValue);
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        loading={loading}
        renderInput={(params) => (
          <TextField
            { ...params }
            label={`${MODE_LIST_ITEMS_LABEL[mode]} 검색`}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
}

export default SelectList;
