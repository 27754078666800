/**
 * 제작: hanseunhyeon
 * 날짜: 23/02/08
 * 내용: next currentList 생성을 위해 prev CurrentList 복사
 */

import { MODE_LIST_ITEMS } from '../../consts/ModeList';

/**
 * currentList update 전 기존 currentList 를 받는 함수
 * @param currentList
 * @return {*}
 */
export function getPrevList(currentList) {
  // console.log('-> getPRevCurrent', currentList);
  const modeLength = Object.keys(MODE_LIST_ITEMS).length;
  const prevListLength = Object.keys(currentList).length;

  const hasMode = prevListLength >= modeLength ? true : false;
  // console.log('-> modeLength', modeLength);
  // console.log('-> currentListLength', currentListLength);
  // console.log('-> hasMode', hasMode);

  const prevList = {};

  if (hasMode) {
    // console.log('기존 리스트 있음', currentList);
    for (const key in MODE_LIST_ITEMS) {
      prevList[key] = { ...currentList[key]};
    }
  }

  if (!hasMode) {
    // currentList에 모드가 없으면 모드 추가
    // console.log('기존리스트 없음', currentList);
    for (const key in MODE_LIST_ITEMS) {
      prevList[key] = {};
    }
  }

  // console.log('-> prevCurrentList', prevCurrentList);
  return prevList;
}
