/**
 * 제작: hanseunghyeon
 * 날짜: 22/11/30
 * 내용: 카카오맵 polyline, marker 에 사용되는 옵션
 */

export const POLYLINE_OPTIONS = {
  strokeWeight: 6, // 선의 두께
  strokeColor: '#333', // 선 색
  strokeOpacity: .8, // 선 투명도
  strokeStyle: 'solid', // 선 스타일
};

export const MARKER_OPTIONS = {
  opacity: .8,
}