/**
 * 제작: hanseunghyeon
 * 날짜: 23/02/15
 * 내용: list store set toggle handler
 */

/**
 * polyline, marker store 의 visible을 토글해주는 공통 함수
 * @param value
 * @param prevList
 * @return {*}
 */
export function setToggleList (value, prevList) {
  const nextList = { ...prevList };
  const { visible } = nextList[value];

  nextList[value].visible = !visible;

  return nextList;
}