/**
 * 제작: hanseunghyeon
 * 날짜: 23/02/13
 * 내용: 버스 노선을 그리기 위한 함수
 */

import { POLYLINE_OPTIONS } from '../../consts/kakaoMapOptions';
const { kakao } = window;

/**
 * 좌표와 색상을 받아서 폴리라인 객체를 생성한다
 * @param path
 * @param color
 * @return {kakao.maps.Polyline}
 */
export function createPolyline(path, color) {
  const newPolyline = new kakao.maps.Polyline({
    ...POLYLINE_OPTIONS,
    path,
    strokeColor: color,
  });

  return newPolyline;
}