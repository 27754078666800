/**
 * 제작 : 한승현
 * 날짜 : 2022/10/12
 * 내용 : 검색창
 */

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  SearchWrapStyled,
  SearchFlexBoxStyled,
  SearchModeListStyled,
  SearchKeywordStyled,
  SearchInputSubmitStyled,
} from '../../stylesheets/search/search';

import {
  MODE_LIST_ITEMS,
} from '../../consts/ModeList';

import ModeNav from '../mode/ModeNav';
import SelectList from './SelectList';
import 'react-toastify/dist/ReactToastify.css';
import {ALERT_MESSAGE} from '../../consts/AlertMessage';
import { useParams } from 'react-router-dom';
import { checkExists } from '../../utils/search/checkExists';
import { errorMessage } from '../../utils/message/alertMessage';
import { useCurrentListStore } from '../../store/currentList';
import { usePolyLineListStore } from '../../store/polyLineList';
import { useMarkerListStore } from '../../store/markerList';
import { warningAlert } from '../../utils/message/alertMessage';

function Search() {
  const { mode } = useParams();
  const [value, setValue] = useState([]);

  const { currentList, setCurrentList } = useCurrentListStore((state) => state);
  const { polyLineList, setPolyLineList } = usePolyLineListStore(state=>state);
  const { markerList, setMarkerList } = useMarkerListStore(state=>state);

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  /**
   * Search From submit
   */
  async function handleOnsubmit() {
    //유저가 선택한 항목 갯 수
    const selectedLength = value.length;

    if (selectedLength < 1) {
      // 1개 보다 적으면
      return errorMessage(ALERT_MESSAGE.KEYWORD); // 경고창
    }

    // 유효성 검사 함수
    const existsResult = checkExists(value, currentList);

    const { hasItem, newItem } = existsResult;
    // console.log('-> hasItem[mode]', hasItem[mode]);
    // console.log('-> newItem[mode]', newItem[mode]);
    // hasList => alert
    warningAlert(hasItem[mode]);

    // addList => set
    await setCurrentList(newItem[mode], currentList);

    if (mode === MODE_LIST_ITEMS.BUS) {
      // 버스면 polyLine 생성
      setPolyLineList(newItem[mode], polyLineList);
    }

    if (mode === MODE_LIST_ITEMS.SHELTER) {
      // 정류장이면 marker 생성
      setMarkerList(newItem[mode], markerList);
    }

    // form reset
    await setValue([]);
    // console.log('-> addItem', addItem);
    // // currentList에 추가하기
    // if (addItem.length > 0) {
    //   // setAddCurrent(currentList, mode, addItem);
    // }
  }

  return (
    <>
      <SearchModeListStyled className="Nav">
        {
          Object.values(MODE_LIST_ITEMS)?.map((item, index) => {
            return <ModeNav key={`mode-item-${index}`} item={item} />
          })
        }
      </SearchModeListStyled>

      <SearchWrapStyled onSubmit={handleSubmit(handleOnsubmit)}>
        <SearchFlexBoxStyled>
          <SearchKeywordStyled htmlFor="keyword">
            <SelectList value={value} setValue={setValue} />
          </SearchKeywordStyled>
          <SearchInputSubmitStyled type="submit" disabled={isSubmitting}>
            보기
          </SearchInputSubmitStyled>
        </SearchFlexBoxStyled>
      </SearchWrapStyled>
    </>
  );
}

export default Search;
