import create from 'zustand';

import { createPolyline } from '../utils/map/createPolyline';
import { getLating } from '../utils/currentList/getBusInfo';
import { createCustomOverlay } from '../utils/map/createCustomOverlay';
import { getKakaoLatLng } from '../utils/map/getKakaoLatLng';
import { setToggleList } from '../utils/setStore/setToggleList';
import { setRemoveList } from '../utils/setStore/setRemoveList';
export const usePolyLineListStore = create(
  (set) => ({
    polyLineList: {},

    /**
     * 클릭된 버튼의 value 로 mode를 교체
     * @param event
     * @return { string }
     */
    setPolyLineList: async (newItemArray, polyLineList) => {
      // console.log('-> setPolyLine scope open');
      // console.log('-> newItem', newItemArray);
      // console.log('-> polyLineList', polyLineList);

      const nextPolyLineList = { ...polyLineList };
      // console.log('-> newPolyLineList', newPolyLineList);

      // newItemArray 순회하면서 polyline 생성
      for (const item of newItemArray) {
        const { value } = item;
        // console.log('-> value ', value, item);

        // 기존 폴리라인 있는지 확인
        const hasItem = value in polyLineList;
        // console.log('-> hasItem', hasItem);

        // 있는 아이템이면 visible만 수정
        if (hasItem) {
          // console.log('있는 아이템 : ', value);
          nextPolyLineList[value].visible = true;
          // console.log('-> newPolyLineList[value]', nextPolyLineList[value]);
        }

        // 새로운 아이템이면 추가하기
        if (!hasItem) {
          // console.log('없는 아이템', value);

          const { color, label } = item;
          // console.log('-> color', color);

          const position = await getLating(item);
          // console.log('-> position', position);

          const path = getKakaoLatLng(position);
          // console.log('-> path', path);

          const polyline = createPolyline(path, color);
          // console.log('-> polyline', polyline);

          const start = createCustomOverlay(color, label, path[0]);
          // console.log('-> start', start);

          const halfIndex = Math.ceil(path.length / 2);
          const half = createCustomOverlay(color, label, path[halfIndex]);
          // console.log('-> end', end);

          const newItem = {
            polyline,
            label: {
              start,
              half,
            },
            visible: true,
          };

          nextPolyLineList[value] = { ...newItem };
        }
      }

      set({
        polyLineList: { ...nextPolyLineList },
      });
    },

    setTogglePolyLineList: (value, polyLineList) => {
      const nextPolylineList = setToggleList(value, polyLineList);

      set({
        polyLineList: { ...nextPolylineList },
      });
    },

    setRemovePolyLineList: async (value, polyLineList) => {
      const nextPolylineList = setRemoveList(value, polyLineList);

      set({
        polyLineList: { ...nextPolylineList },
      });
    },
  }),

  {
    name: 'zustand-polyLineList',
  }
);
