/**
 * 제작 : han seunghyeon
 * 날짜 : 2022-10-06
 * 내용 : Root 페이지
 */
import RoutePage from './RoutePage';
function Root() {
  return <RoutePage />;
}

export default Root;
