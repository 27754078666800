import create from 'zustand';
import { getPrevList } from '../utils/currentList/getPrevList';
import { getStartEndStation } from '../utils/currentList/getBusInfo';
import { ALERT_MESSAGE } from '../consts/AlertMessage';
import { successMessage } from '../utils/message/alertMessage';
import { getBusColor } from '../utils/currentList/getBusColor';
import { MODE_LIST_ITEMS } from '../consts/ModeList';

export const useCurrentListStore = create(
  (set) => ({
    currentList: {
      BUS: {},

      SHELTER: {
        // 10001: {
        //   label: '도봉산역',
        //   value: '10001',
        //   type: 'SHELTER',
        //   X: '127.0454312498',
        //   Y: '37.6891946492',
        // },
        // 10003: {
        //   label: '도봉한신아파트',
        //   value: '10003',
        //   type: 'SHELTER',
        //   X: '127.0459462612',
        //   Y: '37.6840520197',
        // },
      },
    },

    /**
     * 새로운 아이템을 받아 set currentList 한다
     * @param newItemArray
     * @param currentList
     * @return {Promise<void>}
     */
    setCurrentList: async (newItemArray, currentList) => {
      // console.log('-> set in newItemArray', newItemArray);
      // console.log('-> set in currentList', currentList);
      /*
        existsResult: {
          BUS: [ 'label', 'label', ... ],
          SHELTER: [ 'label', 'label', ... ],
        }
       */

      const nextCurrentList = getPrevList(currentList);
      // console.log('-> init nextCurrentList', nextCurrentList);

      // newItemArray 순회하면서 currentLit에 추가
      for (const item of newItemArray) {
        // console.log('item 순회 중', item);
        const { value, type } = item;

        item.visible = true;

        if (type === MODE_LIST_ITEMS.BUS) {
          // 버스 아이템이면
          const { label, routeType } = item;

          // getLating 전달할 params 생성
          const params = {
            busRouteId: value,
            strSrch: label,
          };

          item.color = getBusColor(routeType);
          item.station = await getStartEndStation(params);

          nextCurrentList[type][value] = item;
        }

        if (type === MODE_LIST_ITEMS.SHELTER) {
          // 정류장 아이템이면
          nextCurrentList[type][value] = item;
          // console.log('-> item', item);
        }
      }

      if (newItemArray.length > 0) {
        // 추가아이템이 있으면 성공 메세지
        successMessage(ALERT_MESSAGE.SUCCESS);
      }

      set({
        currentList: { ...nextCurrentList },
      });
    },

    setRemoveList: async (mode, value, currentList) => {
      const nextList = getPrevList(currentList);
      delete nextList[mode][value];

      set({
        currentList: { ...nextList },
      });
    },

    setToggleVisible: async (mode, value, currentList) => {
      const nextList = getPrevList(currentList);
      const { visible } = nextList[mode][value];
      nextList[mode][value].visible = !visible;

      set({
        currentList: { ...nextList },
      });
    },
  }),

  {
    name: 'zustand-currentList',
  }
);
