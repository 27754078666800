/**
 * 제작: hanseunghyeon
 * 날짜: 23/01/31
 * 내용: seoul api 개수 제한이 있다 => 콜백을 이용해 최대 갯수까지 반복 호출 한다.
 */

import axios from 'axios';
import { getNewItems } from './getNewItems';
import { getTotalItemLength } from './getTotalItemLength';
/**
 * seoul api를 호출해서 아이템 최대 갯수까지 재귀함수 호출
 * @param url
 * @param start
 * @param preData
 * @return {Promise<{[p: string]: *}|재귀함수>}
 */
export async function getList(url, service, start, preData) {
  const maxLength = 1000;
  const nextStart = Number(start) + Number(maxLength);
  const end = Number(nextStart) - 1;

  const params = {
    service,
    start,
    end,
  };

  const result = await axios.get(url, { params });
  const newData = await getNewItems(result);

  const nextData = {
    ...preData,
    ...newData,
  };
  // console.log('-> nextData', nextData);

  const totalItemLength = getTotalItemLength(result);
  const nowDataLength = Object.keys(nextData).length;
  // console.log('-> totalItemLength', totalItemLength);
  // console.log('-> nowDataLength', nowDataLength);

  if (nowDataLength >= totalItemLength) {
    // 종료 조건
    return nextData;
  }

  return getList(url, service, nextStart, nextData); // 재귀함수
}
