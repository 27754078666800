/**
 * 제작: hanseunghyeon
 * 날짜: 23/02/14
 * 내용: 버스 노선의 라벨을 보여주기 위한 함수
 */

const { kakao } = window;

/**
 * 버스 노선의 라벨을 생성하는 함수
 * @param color
 * @param label
 * @param position
 * @return {kakao.maps.CustomOverlay}
 */
export function createCustomOverlay (color, label, position) {
  const content = `<div class='polyline' style='background:${color};' >${label}</div>`;

  const customOverlay = new kakao.maps.CustomOverlay({
    position: position,
    content: content,
  });

  return customOverlay;
}