/**
 * 제작: hanseunghyeon
 * 날짜: 23/02/15
 * 내용: 정류장 이름 보여주는 인포 윈도우 생성
 */

const { kakao } = window;

/**
 * InfoWindow 를 만들어서 반환하는 함수
 * @param item
 * @return {Property.Marker}
 */
export function createInfoWindow(item) {
  // console.log('-> item', item);

  const { gpsX, gpsY, label } = item;

  // 마커가 표시될 위치
  const infoWindowPosition = new kakao.maps.LatLng(gpsY, gpsX);

  // 라벨 자르기...
  const info = label.length < 7 ? label : `${label.substr(0, 6)}...`;

  // 인포윈도우 엘리먼트
  const content = `<div style='padding:4px 8px;'>${info}</div>`;

  // 마커를 생성합니다
  const infoWindow = new kakao.maps.InfoWindow({
    content,
    position: infoWindowPosition,
  });

  return infoWindow;
}
