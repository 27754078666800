/**
 * 오브라운 계정인지 확인하기 위한 함수
 * @param response
 */
export function setIsOhbrown(response) {
  const { profileObj } = response;
  const email = profileObj.email;
  const host = 'ohbrown';
  const isOhbrown = email.split('@')[1].includes(host) ? true : false;

  sessionStorage.setItem('isOhbrown', isOhbrown);
}
