/**
 * 제작: hanseunghyeon
 * 날짜: 23/02/08
 * 내용: value를 받아서 currentList에 있는 값 인지 확인
 */

import { MODE_LIST_ITEMS } from '../../consts/ModeList';

/**
 * 입력 값을 새로운 값과 이전에 입력된 값으로 분리한다
 * @param value
 * @param itemList
 * @return {{newItem: {}, hasItem: {}}}
 */
export function checkExists(value, itemList) {
  // value = [ {label, value, type, ... } ]; === submit 된 아이템 배열

  // currentList= {
  //   BUS: {
  //     label: {},
  //     label: {},
  //      ...
  //   },
  //   SHELTER: {
  //     label: {},
  //     label: {},
  //      ...
  //   },
  // }

  // 새로운 아이템
  const hasItem = {};
  // 있는 아이템
  const newItem = {};

  // 빈 배열 생성
  for (const key in MODE_LIST_ITEMS) {
    hasItem[key] = [];
    newItem[key] = [];
  }

  let existsItem;
  // 있는 아이템
  // existsItem: {
  //    BUS: [ value, value, ... ],
  //    SHELTER: [ value, value, ... ],
  // }
  for (const item of value) {
    // console.log('-> item', item);
    // item = { value, label, type, ... }
    const { value } = item;
    // value  = 100000018 === routeId
    // console.log('-> value', value);
    existsItem = getExistsItem(itemList);
    // existsItem = {
    //  BUS: [ '', '', ... ],
    //  SHELTER: [ '', '', ... ],
    // }
    // console.log('-> existsItem', existsItem);
  }

  // return existsItem;

  // value 를 순회하며 existsItem 에 있는지 확인한다
  // existsItem 에 있으면 hasItem 추가한다
  // 없으면 addItem 추가한다
  for (const item of value) {
    const { type, value } = item;
    const result = existsItem[type].includes(value);
    // console.log(`${value} :`, result);
    if (!result) {
      // 없는 아이템이면
      newItem[type].push(item);
    } else {
      // 있는 아이템이면
      hasItem[type].push(item);
    }
  }

  const result = {
    newItem,
    hasItem,
  };

  // console.log('-> result', result);
  return result;
}

/**
 * currentList를 받아서 existsItem을 리턴한다
 * @param currentList
 * @return {{}}
 */
function getExistsItem(currentList) {
  // currentList 를 참고해 existsItem 생성
  const existsItem = {};

  for (const type in currentList) {
    // currnetList의 key를 순회
    // type = [ 'BUS', 'SHELTER', ... ]
    const typeItems = Object.keys(currentList[type]);
    // typeItems === currentList[mode] === [ {...}, {...}, ... ]
    existsItem[type] = [...typeItems];
  }
  return existsItem;
}
