/**
 * 제작: hanseunghyeon
 * 날짜: 23/02/08
 * 내용: bus item 에 필요한 속성을 추가하는 함수들
 */

import { BASE_URL, URL_LIST } from '../../consts/BaseUrl';
import axios from 'axios';

/**
 * 버스 기점, 종점을 주는 함수
 * @param params
 * @return {Promise<{start, end}>}
 */
export async function getStartEndStation(params) {
  const URL = BASE_URL+URL_LIST.BUS;
  const response = await axios.get(URL, {params});
  // console.log('-> response.data', response.data);
  const { data } = response;
  /*
  data = [ { item }, { item }, ... ];

  item = {
    busRouteAbrv: "6101",
    busRouteId: "100100373",
    busRouteNm: "6101",
    corpNm:"한국도심공항  02-551-0077",
    edStationNm: "김포공항",
    firstBusTm: "20230209042500",
    firstLowTm: "20190812000000",
    lastBusTm: "20230209200500",
    lastBusYn: " ",
    lastLowTm: "20190812000000",
    length: "92",
    routeType: "1",
    stStationNm: "창동역",
    term: "40",
  }

  */
  // console.log('-> data', data);

  let station;

  if (!data) {
    station = {
      end: '정보가 제공되지 않습니다',
      start: '정보가 제공되지 않습니다',
    };
  }
  if (data) {
    station = getStation(data, params);
  }
  // console.log('-> station', station);

  return station;
}

/**
 * station {start, end} 를 리턴하는 함수
 * @param data {object} response.data
 * @param params {object} {busRouteId}
 * @return {{start, end}}
 */
function getStation(data, params) {
  const { busRouteId } = params;

  const selectItem = data.filter((item) =>
    // params.busRouteId와 동일한 아이템 선택
    item.busRouteId === busRouteId
  )[0];
  /*
    { ... };
   */
  const { edStationNm, stStationNm } = selectItem;

  return {
    end: edStationNm || null,
    start: stStationNm || null,
  };
}

/**
 * 버스 노선이 운행하는 좌표를 받아오는 함수
 * @param {object} params{key:string, value:string}
 * @return {object} {[posX,posY],[posX,posY]}
 */
export async function getLating(item) {
  const { value, label } = item;
  const params = {
    busRouteId: value,
    strSrch: label,
  };
  
  const URL = BASE_URL+URL_LIST.BUSPATH;
  const response = await axios.get(URL, {params});

  return response.data;
}

