/**
 * 제작: hanseunghyeon
 * 날짜: 23/02/03
 * 내용: object to array 로 반환한다
 */

import { MODE_LIST_ITEMS } from '../../consts/ModeList';
import { getInitData } from './getInitData';
import { SEOUL_SERVICE_NAME } from '../../consts/BaseUrl';

/**
 * {} 타입의 아이템 리스트를 select List에 추가할 [] 타입으로 교체하는 함수
 * @return {Promise<{}>}
 */
export async function getOptions() {
  const itemList = {};
  const options = {};
  const list = Object.keys(MODE_LIST_ITEMS);

  list.map(async (item) => {
    itemList[item] = {};
  });

  for (const mode in itemList) {
    // console.log('-> mode', mode);
    itemList[mode] = await getInitData(SEOUL_SERVICE_NAME[mode]);
  }

  for (const mode in itemList) { // itemList { BUS: {}, SHELTER: {}, }
    // BUS, SHELTER
      options[mode] = [];
    for (const item in itemList[mode]) { // BUS: { 1: {}, 2: {}, ...}
      // item = {1, 2},
      const pushItem = itemList[mode][item];
      options[mode].push(pushItem);
    }
  }

  return options;

}
