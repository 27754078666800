import styled from 'styled-components';

const BUTTON_STYLE = `
  padding: 6px 6px 0;
  font-size: 1.2em;
  transition: all 0.3s;
  color: #999;
  text-align: center;
  border-radius: 2px;
  outline: none;
  background-color: none;
  border: 0;
`;

const BUTTON_CURSOR = `
  cursor: pointer;
`;

const CurrentListWrapStyled = styled.ul``;

const CurrentListItemStyled = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  text-align: left;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
`;

const CurrentListIconBoxStyled = styled.div`
  ${BUTTON_STYLE};
  margin: 6px 4px;
  color: ${(props) => props.color || '#333333'};
`;

const CurrentListInfoStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CurrentListInfoWrapStyled = styled.div``;

const CurrentListItemLabelStyled = styled.span`
  font-weight: 700;
`;

const CurrentListItemIdStyled = styled.span``;

const CurrentListButtonStyle = styled.button`
  ${BUTTON_STYLE}
  ${BUTTON_CURSOR}
  &:hover {
    color: #333;
    //color: #de350b;
    //background-color: #ffbdad;
  }
`;

export {
  CurrentListWrapStyled,
  CurrentListItemStyled,
  CurrentListIconBoxStyled,
  CurrentListInfoStyled,
  CurrentListInfoWrapStyled,
  CurrentListItemLabelStyled,
  CurrentListItemIdStyled,
  CurrentListButtonStyle,
};
