import styled from 'styled-components';

const ModeItemWrapStyled = styled.li`

`;

const ModeItemButtonStyled = styled.button`
  width: 100%;
  padding: 12px;
  text-align: center;
  transition: all .3s;
  font-weight: 700;
  background: #e9e9e9;
  border: 0;
  border-bottom: 6px solid #333;

  &.active {
    color:#fff;
    background: #333;
    border-bottom: 6px solid #e9e9e9;
  }

  &:hover {
    cursor: pointer;
  }
`;


export {
    ModeItemWrapStyled,
    ModeItemButtonStyled,
};