/**
 * 제작: hanseunghyeon
 * 날짜: 23/02/08
 * 내용: alert message 출력하는 함수 모음
 */

import { toast } from 'react-toastify';
import { TOSTIFY_OPTIONS } from '../../consts/AlertMessage';

/**
 * warning message
 * @param {array} hasItem 중복된 아이템 배열
 */
export function warningAlert(hasItem) {
  // console.log('-> hasItem', hasItem);
  const errorLength = hasItem.length;
  if (errorLength > 0) {
    const errorItem = hasItem
      ?.map((item) => {
        return ` [${item.label}]`;
      })
      .toString();
    const msg = `중복 값 : ${errorItem}`;
    warningMessage(msg);
  }
}

/**
 * success message
 * @param {string} msg
 */
export function successMessage(msg) {
  toast.success(msg, TOSTIFY_OPTIONS);
}

/**
 * error message
 * @param {string} msg
 */
export const errorMessage = (msg) => {
  toast.error(msg, TOSTIFY_OPTIONS);
};

/**
 * 메세지를 받아서 warning alert 해주는 함수
 * @param {string} msg
 */
export const warningMessage = (msg) => {
  toast.warning(msg, TOSTIFY_OPTIONS);
};
