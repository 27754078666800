/**
 * 제작: hanseunghyeon
 * 날짜: 22/11/22
 * 내용: 버스 타입과 컬러 선언
 */

const NONE_COLOR = '#8841d2';

const BUS_ROUTE_TYPES = {
    '공용': {LABEL: '공용', COLOR: NONE_COLOR},
    '공항': {LABEL: '공항', COLOR: '#65a6d2'},
    '마을': {LABEL: '마을', COLOR: '#87c700'},
    '간선': {LABEL: '간선', COLOR: '#386de8'},
    '지선': {LABEL: '지선', COLOR: '#3cc344'},
    '순환': {LABEL: '순환', COLOR: '#ffba00'},
    '광역': {LABEL: '광역', COLOR: '#fb5852'},
    '인천': {LABEL: '인천', COLOR: NONE_COLOR},
    '경기': {LABEL: '경기', COLOR: '#87c700'},
    '폐지': {LABEL: '폐지', COLOR: '#333333'},
    '관광': {LABEL: '투어', COLOR: NONE_COLOR},
}

export default BUS_ROUTE_TYPES;