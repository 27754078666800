/**
 * 제작 : 한승현
 * 날짜 : 2022/10/11
 * 내용 : Header
 */

import React from 'react';

import {
  HeaderWrapStyled,
  HeaderTitleStyled,
} from '../../stylesheets/header/header';

function Header() {
  return (
    <HeaderWrapStyled>
      <HeaderTitleStyled>BUS MAP</HeaderTitleStyled>
    </HeaderWrapStyled>
  );
}

Header.propTypes = {};
Header.defaultProps = {};

export default Header;
