import create from 'zustand';
import { createMarker } from '../utils/map/createMarker';
import { setToggleList } from '../utils/setStore/setToggleList';
import { setRemoveList } from '../utils/setStore/setRemoveList';
import { createInfoWindow } from '../utils/map/createInfoWindow';

export const useMarkerListStore = create(
  (set) => ({
    markerList: {},

    setMarkerList: async(newItemArray, markerList) => {
      // console.log('-> newItemArray', newItemArray);
      // console.log('-> markerList', markerList);

      const nextMarkerList = { ...markerList };

      // newItemArray 순회하면서 marker 생성
      for (const item of newItemArray) {
        const { value, label } = item;

        // 기존 마커 있는지 확인
        const hasItem = value in markerList;
        // console.log('-> hasItem', hasItem);

        // 있는 아이템이면 visible만 수정
        if (hasItem) {
          // console.log('있는 아이템 : ', value);
          markerList[value].visible = true;
          // console.log('-> markerList[value]', markerList[value]);
        }

        // 새로운 아이템이면 추가하기
        if (!hasItem) {
          // console.log('없는 아이템', value);

          // 마커 생성
          const marker = createMarker(item);
          const markerInfo = createInfoWindow(item);
          // 윈도우 윈포


          const newItem = {
            label,
            marker,
            markerInfo,
            visible: true,
          };

          nextMarkerList[value] = { ...newItem };
        }
      }

      set({
        markerList: { ...nextMarkerList },
      })
    },

    setToggleMarkerList: (value, markerList) => {
      const nextMarkerList = setToggleList(value, markerList);

      set({
        markerList: { ...nextMarkerList }
      })
    },

    setRemoveMarkerList: (value, markerList) => {
      const nextMarkerList = setRemoveList(value, markerList);

      set({
        markerList: {...nextMarkerList},
      })
    },

  }),

  {
    name: 'zustand-markerList',
  }
);
